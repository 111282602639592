import { defineStore } from 'pinia'
import { API_STATUS } from '@/utils/enums'
import { exibeMensagemErroAxios } from '@/utils/toast-messenger'
import apiClient from '@/utils/api-client'

export const useEspecialidadeStore = defineStore('especialidade', {
  state: () => {
    return {
      especialidades: [],
      especialidade: null,
      writingStatus: API_STATUS.IDLE,
      readingStatus: API_STATUS.IDLE,
    }
  },

  getters: {},

  actions: {
    /**
     * Consulta todas as especialidades.
     */
    async fetchModels() {
      this.readingStatus = API_STATUS.LOADING
      try {
        const response = await apiClient.get(`/api/especialidades`)
        this.especialidades = response.data.data
        this.readingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.readingStatus = API_STATUS.FAILED
      }
    },

    /**
     * Consulta uma especialidade.
     * @param {Number} id
     */
    async fetchModel(id) {
      this.readingStatus = API_STATUS.LOADING
      this.especialidade = null
      try {
        const response = await apiClient.get(`/api/especialidades/${id}`)
        this.especialidade = response.data.data
        this.readingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.readingStatus = API_STATUS.FAILED
      }
    },

    /**
     * Insere uma nova especialidade.
     * @param {Object} payload
     */
    async createModel(payload) {
      this.writingStatus = API_STATUS.LOADING
      try {
        const dados = JSON.stringify(payload)
        await apiClient.post(`/api/especialidades`, dados)
        this.writingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.writingStatus = API_STATUS.FAILED
      }
    },

    /**
     * Atualiza os dados de uma especialidade.
     * @param {Number} id
     * @param {Object} payload
     */
    async updateModel(id, payload) {
      this.writingStatus = API_STATUS.LOADING
      try {
        const dados = JSON.stringify(payload)
        await apiClient.put(`/api/especialidades/${id}`, dados)
        this.writingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.writingStatus = API_STATUS.FAILED
      }
    },

    /**
     * Exclui uma especialidade.
     * @param {Number} id
     */
    async destroyModel(id) {
      this.writingStatus = API_STATUS.LOADING
      try {
        await apiClient.delete(`/api/especialidades/${id}`)
        this.writingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.writingStatus = API_STATUS.FAILED
      }
    },
  },
})
