import DashboardView from '@/views/DashboardView.vue'
import LoginView from '@/views/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'login' },
    // redirect: { name: 'painel' }, // FIXME: redirect depende se esta logado
  },
  {
    path: '/login',
    name: 'login',
    components: {
      login: LoginView,
    },
    meta: { guest: true },
  },
  {
    path: '/painel',
    name: 'painel',
    component: DashboardView,
    meta: { requiresAuth: true },
  },
]

export default routes
