import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

const PATTERN_CPF = /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/
const PATTERN_CEP = /^[0-9]{5}-[0-9]{3}$/
const PATTERN_EMAIL = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
// TODO: suporte para e-mails com + --> lorem.ipsum+teste@gmail.com

dayjs.extend(customParseFormat)

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Campo obrigatório',
        limit100: (value) =>
          !value || value.length <= 100 || 'Limite de 100 caracteres',
        limit200: (value) =>
          !value || value.length <= 200 || 'Limite de 200 caracteres',
        zeroOrMore: (value) => !value || value >= 0 || 'Valor mínimo: 0',
        cpf: (value) => {
          return !value || PATTERN_CPF.test(value) || 'Formato de CPF inválido'
        },
        cep: (value) => {
          return !value || PATTERN_CEP.test(value) || 'CEP inválido'
        },
        dateDDMMYYYY: (value) => {
          return (
            !value ||
            dayjs(value, 'DD/MM/YYYY', true).isValid() ||
            'Data inválida'
          )
        },
        email: (value) => {
          return !value || PATTERN_EMAIL.test(value) || 'E-mail inválido'
        },
      },
    }
  },
}
