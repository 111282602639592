import axios from 'axios'
import { addAuthInterceptors } from '@/utils/axios-auth-interceptors'

const API_URL = process.env.VUE_APP_API_URL

const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  timeout: 100000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

addAuthInterceptors(apiClient)

export default apiClient
