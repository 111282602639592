<template>
  <v-btn
    icon
    @click="$emit('click')"
    :to="to"
    color="error"
    title="Excluir item"
    data-cy="link-excluir"
  >
    <v-icon> mdi-delete </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BotaoExcluirMini',
  props: {
    to: Object,
  },
}
</script>
