<template>
  <form-card-container
    titulo="Novo Profissional"
    @cancelar="voltarParaLista"
    :exibeAlertaInvalido="!isFormValido"
  >
    <template v-slot:default>
      <v-form
        ref="formNovo"
        v-model="isFormValido"
        :disabled="isProcessing"
        @submit.prevent="salvar"
        data-cy="main-form"
        lazy-validation
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="modelForInsert.nome"
              :rules="[rules.required]"
              label="Nome Completo"
              required
              autofocus
              data-cy="nome"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="modelForInsert.documento"
              label="Documento"
              hint="Informe o tipo do documento. Ex: CRM 1234-SP"
              data-cy="documento"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="modelForInsert.telefone"
              label="Telefone"
              placeholder="(00) 0000-0000"
              v-mask="maskTelefone"
              hint="digite apenas números"
              prepend-icon="mdi-phone"
              data-cy="telefone"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              label="Especialidades"
              v-model="modelForInsert.especialidades_ids"
              :items="lista_especialidades"
              :loading="isLoadingEspecialidades"
              item-text="titulo"
              item-value="id"
              chips
              deletable-chips
              multiple
              :menu-props="{ auto: true }"
              data-cy="especialidades"
            ></v-select>
          </v-col>
        </v-row>

        <input type="submit" hidden />
      </v-form>
    </template>
    <template v-slot:acao-principal>
      <botao-salvar
        @click="salvar"
        :loading="isProcessing"
        :disabled="isProcessing"
      />
    </template>
  </form-card-container>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useEspecialidadeStore } from '@/stores/especialidade-store'
import { useProfissionalStore } from '@/stores/profissional-store'
import { API_STATUS } from '@/utils/enums'
import FormCardContainer from '@/components/FormCardContainer.vue'
import BotaoSalvar from '@/components/botoes/BotaoSalvar.vue'
import validacoesComuns from '@/mixins/validacoes-comuns'
import { exibeMensagemSucesso } from '@/utils/toast-messenger'

export default {
  name: 'ProfissionalFormCardNew',

  components: {
    FormCardContainer,
    BotaoSalvar,
  },

  mixins: [validacoesComuns],

  computed: {
    ...mapState(useProfissionalStore, ['writingStatus']),
    ...mapState(useEspecialidadeStore, {
      lista_especialidades: 'especialidades',
      loading_especialidades: 'readingStatus',
    }),

    isProcessing() {
      return this.writingStatus === API_STATUS.LOADING
    },

    isLoadingEspecialidades() {
      return this.loading_especialidades === API_STATUS.LOADING
    },

    maskTelefone() {
      return (this.modelForInsert?.telefone?.length ?? 0) < 15
        ? '(##) ####-####?#'
        : '(##) #####-####'
    },
  },

  data() {
    return {
      isFormValido: false,
      modelForInsert: {},
    }
  },

  methods: {
    ...mapActions(useProfissionalStore, ['createModel']),

    voltarParaLista() {
      this.$router.push({ name: 'profissionaisLista' })
    },

    async salvar() {
      // Precisa chamar o 'validate' devido ao lazy-validation do form
      if (this.$refs.formNovo.validate()) {
        await this.createModel(this.modelForInsert)
        if (this.writingStatus === API_STATUS.SUCCEEDED) {
          this.voltarParaLista()
          exibeMensagemSucesso('Profissional inserido com sucesso!')
        }
      }
    },
  },
}
</script>

<style></style>
