import Vue from 'vue'
import translateAxiosError from './axios-error-friendlizer'

const TIMEOUT_SUCCESS = 5000
const TIMEOUT_INFO = 5000
const TIMEOUT_ERROR = 10000

export const exibeMensagemErro = (mensagem) => {
  if (!mensagem) return
  Vue.$toast.error(mensagem, { timeout: TIMEOUT_ERROR })
}

export const exibeMensagemErroAxios = (error) => {
  const mensagem = translateAxiosError(error)
  exibeMensagemErro(mensagem)
}

export const exibeMensagemInfo = (mensagem) => {
  if (!mensagem) return
  Vue.$toast.info(mensagem, { timeout: TIMEOUT_INFO })
}

export const exibeMensagemSucesso = (mensagem) => {
  if (!mensagem) return
  Vue.$toast.success(mensagem, { timeout: TIMEOUT_SUCCESS })
}
