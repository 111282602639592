<template>
  <div>
    <header-secao :titulo="tituloSecao">
      <botao-novo :rotulo="rotuloNovo" :to="{ name: routeNovo }" />
    </header-secao>

    <!-- Lista -->
    <router-view name="lista" />

    <!-- Formulário -->
    <v-dialog
      :value="modalAberto"
      persistent
      scrollable
      :max-width="modalMaxWidth"
      data-cy="modal"
    >
      <router-view name="formulario" />
    </v-dialog>
  </div>
</template>

<script>
import HeaderSecao from '@/components/HeaderSecao.vue'
import BotaoNovo from '@/components/botoes/BotaoNovo.vue'

export default {
  components: { HeaderSecao, BotaoNovo },

  props: {
    tituloSecao: String,
    rotuloNovo: String,
    routeNovo: String,
    modalMaxWidth: {
      type: String,
      default: '600px',
    },
  },

  computed: {
    modalAberto() {
      return this.$route.meta.requerModal
    },
  },
}
</script>

<style></style>
