<template>
  <v-overlay color="teal darken-4">
    <v-container>
      <v-card elevation="8" light max-width="500" data-cy="login-container">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" sm="5" align-self="center">
              <v-img
                src="@/assets/logo-200px.png"
                alt="Logo Saúde Essencial"
                max-width="200"
                class="my-2 mx-auto"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-form @submit.prevent="autenticar" v-model="isFormValido">
                <v-container>
                  <v-text-field
                    v-model="username"
                    :rules="[rules.required]"
                    label="Usuário"
                    prepend-inner-icon="mdi-account"
                    autofocus
                    data-cy="username"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :rules="[rules.required]"
                    label="Senha"
                    prepend-inner-icon="mdi-lock"
                    type="password"
                    data-cy="password"
                  ></v-text-field>
                  <v-alert
                    :value="!!mensagemErro"
                    type="error"
                    dense
                    outlined
                    text
                    :icon="false"
                    transition="scale-transition"
                  >
                    {{ mensagemErro }}
                  </v-alert>
                  <v-btn
                    color="primary"
                    class="my-4"
                    @click="autenticar"
                    :disabled="!isFormValido"
                    :loading="isLoading"
                    block
                    data-cy="submit"
                    >Acessar</v-btn
                  >
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </v-overlay>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth-store'
import { API_STATUS } from '@/utils/enums'
import validacoesComuns from '@/mixins/validacoes-comuns'

export default {
  name: 'LoginForm',

  mixins: [validacoesComuns],

  data() {
    return {
      isFormValido: false,
      username: '',
      password: '',
      mensagemErro: '',
    }
  },

  computed: {
    ...mapState(useAuthStore, [
      'isAutenticado',
      'loginFailedResponseMessage',
      'authenticatingStatus',
      'readingStatus',
    ]),

    isLoading() {
      return (
        this.authenticatingStatus === API_STATUS.LOADING ||
        this.readingStatus === API_STATUS.LOADING
      )
    },
  },

  methods: {
    ...mapActions(useAuthStore, ['login', 'fetchAutenticado']),

    async autenticar() {
      if (this.isFormValido) {
        this.mensagemErro = ''
        await this.login({ name: this.username, password: this.password })

        if (this.authenticatingStatus === API_STATUS.SUCCEEDED) {
          await this.fetchAutenticado()
        }
      }
    },
  },

  watch: {
    loginFailedResponseMessage: function () {
      if (this.loginFailedResponseMessage) {
        this.mensagemErro = this.loginFailedResponseMessage
      }
    },

    isAutenticado: function () {
      if (this.isAutenticado) this.$router.push({ name: 'painel' })
    },
  },
}
</script>

<style></style>
