<template>
  <v-btn icon @click="$emit('click')" data-cy="link-cancelar">
    <v-icon>mdi-close</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BotaoCancelarMini',
}
</script>
