<template>
  <form-card-container
    titulo="Alterar Paciente"
    @cancelar="voltarParaLista"
    :exibeAlertaInvalido="!isFormValido"
    :loading="!modelForEdit"
  >
    <template v-slot:default>
      <v-form
        v-model="isFormValido"
        :disabled="isProcessing"
        @submit.prevent="salvar"
        data-cy="main-form"
      >
        <v-row>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="modelForEdit.nome"
              :rules="[rules.required, rules.limit100]"
              label="Nome Completo"
              maxlength="100"
              counter
              required
              autofocus
              data-cy="nome"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              v-model="helper_data_nascimento_dma"
              :rules="[rules.dateDDMMYYYY]"
              label="Nascimento"
              placeholder="dd/mm/aaaa"
              v-mask="'##/##/####'"
              :hint="idade"
              persistent-hint
              prepend-icon="mdi-calendar"
              maxlength="10"
              data-cy="nascimento"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-select
              v-model="modelForEdit.sexo"
              :items="listaGeneros"
              item-text="rotulo"
              item-value="sigla"
              label="Sexo"
              clearable
              data-cy="sexo"
            ></v-select>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <v-text-field
              v-model="modelForEdit.peso"
              :rules="[rules.zeroOrMore]"
              label="Peso"
              type="number"
              min="0"
              suffix="kg"
              maxlength="10"
              data-cy="peso"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <v-text-field
              v-model="modelForEdit.altura"
              :rules="[rules.zeroOrMore]"
              label="Altura"
              type="number"
              min="0"
              suffix="cm"
              placeholder="0,00"
              data-cy="altura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-checkbox
              v-model="modelForEdit.possui_filhos"
              label="Possui Filhos?"
              data-cy="filhos"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="modelForEdit.rg"
              label="RG"
              maxlength="30"
              data-cy="rg"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="modelForEdit.cpf"
              :rules="[rules.cpf]"
              label="CPF"
              maxlength="14"
              placeholder="000.000.000-00"
              v-mask="'###.###.###-##'"
              hint="digite apenas números"
              data-cy="cpf"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="modelForEdit.sus"
              label="Cartão SUS"
              maxlength="18"
              placeholder="000 0000 0000 0000"
              v-mask="'### #### #### ####'"
              hint="digite apenas números"
              data-cy="sus"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="modelForEdit.email"
              :rules="[rules.limit200, rules.email]"
              label="E-mail"
              placeholder="fulano@dital.com.br"
              maxlength="200"
              counter
              prepend-icon="mdi-email"
              data-cy="email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="modelForEdit.telefone_1"
              label="Telefone 1"
              placeholder="(00) 0000-0000"
              v-mask="maskTelefone"
              hint="digite apenas números"
              prepend-icon="mdi-phone"
              data-cy="fone1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="modelForEdit.telefone_2"
              label="Telefone 2"
              placeholder="(00) 0000-0000"
              v-mask="maskCelular"
              hint="digite apenas números"
              prepend-icon="mdi-cellphone"
              data-cy="fone2"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="modelForEdit.nome_mae"
              :rules="[rules.limit100]"
              label="Nome da Mãe"
              maxlength="100"
              counter
              data-cy="mae"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="modelForEdit.nome_pai"
              :rules="[rules.limit100]"
              label="Nome do Pai"
              maxlength="100"
              counter
              data-cy="pai"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="modelForEdit.cep"
              :rules="[rules.cep]"
              label="CEP"
              maxlength="9"
              placeholder="00000-000"
              v-mask="'#####-###'"
              hint="digite apenas números"
              prepend-icon="mdi-map-marker"
              data-cy="cep"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="9" md="7">
            <v-text-field
              v-model="modelForEdit.endereco_rua"
              :rules="[rules.limit200]"
              label="Endereço"
              maxlength="200"
              counter
              data-cy="rua"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="2">
            <v-text-field
              v-model="modelForEdit.endereco_numero"
              label="Número"
              data-cy="numero"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="modelForEdit.bairro"
              :rules="[rules.limit100]"
              label="Bairro"
              maxlength="100"
              counter
              data-cy="bairro"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="modelForEdit.municipio"
              :rules="[rules.limit100]"
              label="Município"
              maxlength="100"
              counter
              data-cy="cidade"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="modelForEdit.uf"
              :items="listaEstadosBrasil"
              item-text="nome"
              item-value="uf"
              label="Estado"
              clearable
              :menu-props="{ auto: true }"
              data-cy="estado"
            ></v-select>
          </v-col>
        </v-row>

        <input type="submit" hidden />
      </v-form>
    </template>
    <template v-slot:acao-principal>
      <botao-salvar
        @click="salvar"
        :loading="isProcessing"
        :disabled="!modelForEdit || isProcessing"
      />
    </template>
  </form-card-container>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { usePacienteStore } from '@/stores/paciente-store'
import { API_STATUS } from '@/utils/enums'
import FormCardContainer from '@/components/FormCardContainer.vue'
import BotaoSalvar from '@/components/botoes/BotaoSalvar.vue'
import listasComuns from '@/mixins/listas-comuns'
import filtrosFormatacao from '@/mixins/filtros-formatacao'
import validacoesComuns from '@/mixins/validacoes-comuns'
import { exibeMensagemSucesso } from '@/utils/toast-messenger'

export default {
  name: 'PacienteFormCardEdit',

  components: {
    FormCardContainer,
    BotaoSalvar,
  },

  props: ['id'],

  mixins: [listasComuns, filtrosFormatacao, validacoesComuns],

  computed: {
    ...mapState(usePacienteStore, [
      'paciente',
      'readingStatus',
      'writingStatus',
    ]),

    isProcessing() {
      return this.writingStatus === API_STATUS.LOADING
    },

    idade() {
      const nasc = this.helper_data_nascimento_dma
      return this.$options.filters.formatoIdade(nasc)
    },

    maskTelefone() {
      return (this.modelForEdit?.telefone_1?.length ?? 0) < 15
        ? '(##) ####-####?#'
        : '(##) #####-####'
    },

    maskCelular() {
      return (this.modelForEdit?.telefone_2?.length ?? 0) < 15
        ? '(##) ####-####?#'
        : '(##) #####-####'
    },
  },

  data() {
    return {
      isFormValido: false,
      modelForEdit: null,
      helper_data_nascimento_dma: '',
    }
  },

  methods: {
    ...mapActions(usePacienteStore, ['fetchModel', 'updateModel']),

    voltarParaLista() {
      this.$router.push({ name: 'pacientesLista' })
    },

    async salvar() {
      if (this.isFormValido) {
        // tratamento especial para data de nascimento (DD/MM/YYYY -> YYYY-MM-DD)
        this.modelForEdit.data_nascimento =
          this.$options.filters.formatoDataIso(this.helper_data_nascimento_dma)

        await this.updateModel(this.id, this.modelForEdit)
        if (this.writingStatus === API_STATUS.SUCCEEDED) {
          this.voltarParaLista()
          exibeMensagemSucesso('Paciente alterado com sucesso!')
        }
      }
    },
  },

  async created() {
    await this.fetchModel(this.id)
    switch (this.readingStatus) {
      case API_STATUS.FAILED:
        this.voltarParaLista()
        break

      case API_STATUS.SUCCEEDED:
        this.modelForEdit = JSON.parse(JSON.stringify(this.paciente))
        // tratamento especial para data de nascimento (YYYY-MM-DD -> DD/MM/YYYY)
        this.helper_data_nascimento_dma = this.$options.filters.formatoData(
          this.modelForEdit.data_nascimento
        )
        break
    }
  },
}
</script>

<style></style>
