<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          v-model="busca"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
          outlined
          dense
          clearable
          role="searchbox"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      class="app-data-table"
      :headers="tableHeaders"
      :items="lista_especialidades"
      :loading="isLoading"
      :search="busca"
      :items-per-page="-1"
      data-cy="main-data-table"
    >
      <template v-slot:[`item.profissionais_ids`]="{ item }">
        <div class="d-relative">
          <template
            v-for="(profissional, index) in filtraListaProfissionais(
              item.profissionais_ids
            )"
          >
            <v-chip
              class="mr-2"
              color="cyan darken-3"
              outlined
              :key="profissional.id"
              v-if="index < 3"
              >{{ profissional.nome }}</v-chip
            >
            <v-chip
              class="last-chip"
              color="cyan darken-3"
              outlined
              label
              :key="profissional.id"
              v-if="index === 3"
            >
              +{{ item.profissionais_ids.length - 3 }} outro(s)...
            </v-chip>
          </template>
        </div>
      </template>
      <template v-slot:[`item.crud_actions`]="{ item }">
        <botao-alterar-mini
          :to="{ name: 'especialidadesAlterar', params: { id: item.id } }"
          class="mr-2"
        />
        <botao-excluir-mini
          :to="{ name: 'especialidadesExcluir', params: { id: item.id } }"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useEspecialidadeStore } from '@/stores/especialidade-store'
import { useProfissionalStore } from '@/stores/profissional-store'
import { API_STATUS } from '@/utils/enums'
import BotaoAlterarMini from '@/components/botoes/BotaoAlterarMini.vue'
import BotaoExcluirMini from '@/components/botoes/BotaoExcluirMini.vue'
import helperDadosRelacionados from '@/mixins/helper-dados-relacionados'

export default {
  name: 'EspecialidadeDataTable',

  components: {
    BotaoAlterarMini,
    BotaoExcluirMini,
  },

  mixins: [helperDadosRelacionados],

  data() {
    return {
      busca: '',
      tableHeaders: [
        { text: 'Título', value: 'titulo' },
        {
          text: 'Profissionais',
          value: 'profissionais_ids',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Ações',
          value: 'crud_actions',
          sortable: false,
          filterable: false,
          class: 'header-crud-action',
          cellClass: 'cel-crud-action',
        },
      ],
    }
  },

  computed: {
    ...mapState(useEspecialidadeStore, {
      lista_especialidades: 'especialidades',
      loading_especialidades: 'readingStatus',
      updating_especialidades: 'writingStatus',
    }),
    ...mapState(useProfissionalStore, {
      lista_profissionais: 'profissionais',
      loading_profissionais: 'readingStatus',
    }),

    isLoading() {
      return (
        this.loading_especialidades === API_STATUS.LOADING ||
        this.loading_profissionais === API_STATUS.LOADING
      )
    },
  },

  methods: {
    ...mapActions(useEspecialidadeStore, {
      fetchEspecialidades: 'fetchModels',
    }),
    ...mapActions(useProfissionalStore, { fetchProfissionais: 'fetchModels' }),

    carregarDados() {
      this.fetchEspecialidades()
      this.fetchProfissionais()
    },

    filtraListaProfissionais(idsProfissionais) {
      return this.filtraListaRelacionada(
        idsProfissionais,
        this.lista_profissionais
      )
    },
  },

  created() {
    this.carregarDados()
  },

  watch: {
    updating_especialidades: function () {
      // verifica se houve mudança nos dados para atualizar a lista
      if (this.updating_especialidades === API_STATUS.SUCCEEDED) {
        this.carregarDados()
      }
    },
  },
}
</script>

<style>
.header-crud-action {
  width: 150px;
}
.cel-crud-action {
  white-space: nowrap;
}
.d-relative {
  position: relative;
  min-height: 1rem;
}
.d-relative .v-chip {
  margin: 4px 0px;
}
.d-relative .v-chip.last-chip {
  border: 0;
  font-style: italic;
}
.loading-image {
  position: absolute;
  left: 0;
}
</style>
