<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          v-model="busca"
          append-icon="mdi-magnify"
          label="Pesquisar"
          placeholder="pesquisar por nome ou cpf (apenas números)"
          single-line
          hide-details
          outlined
          dense
          clearable
          role="searchbox"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      class="app-data-table"
      :headers="tableHeaders"
      :items="pacientes"
      :loading="isLoading"
      :search="busca"
      :items-per-page="10"
      data-cy="main-data-table"
    >
      <template v-slot:[`item.data_nascimento`]="{ item }">
        {{ item.data_nascimento | formatoIdade }}
      </template>
      <template v-slot:[`item.cpf`]="{ item }">
        {{ item.cpf | formatoCpfProtegido }}
      </template>
      <template v-slot:[`item.cidade_estado`]="{ item }">
        {{ item.municipio }} / {{ item.uf }}
      </template>
      <template v-slot:[`item.crud_actions`]="{ item }">
        <botao-alterar-mini
          :to="{ name: 'pacientesAlterar', params: { id: item.id } }"
          class="mr-2"
        />
        <botao-excluir-mini
          :to="{ name: 'pacientesExcluir', params: { id: item.id } }"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { usePacienteStore } from '@/stores/paciente-store'
import { API_STATUS } from '@/utils/enums'
import filtrosFormatacaoMixin from '@/mixins/filtros-formatacao'
import BotaoAlterarMini from '@/components/botoes/BotaoAlterarMini.vue'
import BotaoExcluirMini from '@/components/botoes/BotaoExcluirMini.vue'

export default {
  name: 'PacienteDataTable',

  components: {
    BotaoAlterarMini,
    BotaoExcluirMini,
  },

  mixins: [filtrosFormatacaoMixin],

  data() {
    return {
      busca: '',
      tableHeaders: [
        { text: 'Nome', value: 'nome' },
        { text: 'Idade', value: 'data_nascimento', filterable: false },
        { text: 'CPF', value: 'cpf' },
        { text: 'Município', value: 'cidade_estado', filterable: false },
        {
          text: 'Ações',
          value: 'crud_actions',
          sortable: false,
          filterable: false,
          class: 'header-crud-action',
          cellClass: 'cel-crud-action',
        },
      ],
    }
  },

  computed: {
    ...mapState(usePacienteStore, [
      'pacientes',
      'readingStatus',
      'writingStatus',
    ]),

    isLoading() {
      return this.readingStatus === API_STATUS.LOADING
    },
  },

  methods: {
    ...mapActions(usePacienteStore, ['fetchModels']),

    carregarDados() {
      this.fetchModels()
    },
  },

  created() {
    this.carregarDados()
  },

  watch: {
    writingStatus: function () {
      // verifica se houve mudança nos dados para atualizar a lista
      if (this.writingStatus === API_STATUS.SUCCEEDED) {
        this.carregarDados()
      }
    },
  },
}
</script>

<style>
.header-crud-action {
  width: 150px;
}
.cel-crud-action {
  white-space: nowrap;
}
</style>
