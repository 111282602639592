import { defineStore } from 'pinia'
import { API_STATUS } from '@/utils/enums'
import {
  exibeMensagemErroAxios,
  exibeMensagemInfo,
} from '@/utils/toast-messenger'
import apiClient from '@/utils/api-client'

const FLAG_AUTENTICADO = 'autenticado'

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      usuario: null,
      loginFailedResponseMessage: '',
      authenticatingStatus: API_STATUS.IDLE,
      readingStatus: API_STATUS.IDLE,
      closingStatus: API_STATUS.IDLE,
    }
  },

  getters: {
    isAutenticado() {
      return !!this.usuario
    },
    deveRestaurarSessao() {
      return localStorage.getItem(FLAG_AUTENTICADO) === '1'
    },
    isAdministrador() {
      return this.usuario?.nivelAcesso === 'admin'
    },
    isOperador() {
      return this.usuario?.nivelAcesso === 'operador'
    },
    isProfissional() {
      return this.usuario?.nivelAcesso === 'profissional'
    },
    getNomeExibicao() {
      return this.usuario ? this.usuario.apelido ?? this.usuario.username : ''
    },
  },

  actions: {
    async login(payload) {
      this.loginFailedResponseMessage = ''
      this.authenticatingStatus = API_STATUS.LOADING

      // Tenta realizar a autenticação via laravel sanctum
      // O token de autenticação é injetado via interceptor do axios
      try {
        const dados = JSON.stringify(payload)
        await apiClient.post(`/login`, dados)
        this.authenticatingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        if (error.response && error.response.status === 406) {
          // Erro - 406 Not Acceptable - indica usuário já logado
          exibeMensagemInfo('Usuário já logado, carregando dados...')
          this.authenticatingStatus = API_STATUS.SUCCEEDED
        } else if (error.response && error.response.status === 422) {
          // Erro - 422 Unprocessable Entity - indica erro de validação
          // Este tipo de mensagem de erro deve aparecer apenas dentro do form.
          this.loginFailedResponseMessage =
            'Nome de usuário ou senha incorretos.'
          this.authenticatingStatus = API_STATUS.FAILED
        } else {
          this.loginFailedResponseMessage = 'Erro na autenticação.'
          this.authenticatingStatus = API_STATUS.FAILED
          exibeMensagemErroAxios(error) // TODO: throw do erro que será tratado no interceptor (?)
        }
      }
    },

    async logout() {
      this.closingStatus = API_STATUS.LOADING
      try {
        await apiClient.post(`/logout`)
        this.closingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        this.closingStatus = API_STATUS.FAILED
      }

      this.usuario = null
      localStorage.removeItem(FLAG_AUTENTICADO)
    },

    async fetchAutenticado() {
      this.$reset()
      this.readingStatus = API_STATUS.LOADING
      try {
        const response = await apiClient.get(`/api/autenticado`)
        if (response.data.name) {
          this.usuario = {
            username: response.data.name,
            apelido: response.data.apelido,
            nivelAcesso: response.data.role,
          }

          // define uma flag no localStorage para restaurar o
          // acesso caso a página seja recarregada.
          localStorage.setItem(FLAG_AUTENTICADO, '1')

          this.readingStatus = API_STATUS.SUCCEEDED
        } else {
          this.readingStatus = API_STATUS.FAILED
          exibeMensagemErroAxios('Erro ao carregar dados do usuário')
        }
      } catch (error) {
        this.readingStatus = API_STATUS.FAILED
        exibeMensagemErroAxios(error)
      }
    },
  },
})
