import ListaView from '@/views/ListaCrudView.vue'
import FormPlaceholderView from '@/components/FormPlaceholder.vue'
import PacienteDataTable from '@/components/pacientes/PacienteDataTable.vue'
import PacienteFormNew from '@/components/pacientes/PacienteFormCardNew.vue'
import PacienteFormEdit from '@/components/pacientes/PacienteFormCardEdit.vue'
import PacienteDelete from '@/components/pacientes/PacienteFormCardDelete.vue'

const routes = [
  {
    path: '/pacientes',
    component: ListaView,
    props: {
      tituloSecao: 'Pacientes',
      rotuloNovo: 'Novo Paciente',
      routeNovo: 'pacientesInserir',
      modalMaxWidth: '800px',
    },
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'pacientesLista',
        components: {
          lista: PacienteDataTable,
          formulario: FormPlaceholderView,
        },
      },
      {
        path: 'inserir',
        name: 'pacientesInserir',
        components: {
          lista: PacienteDataTable,
          formulario: PacienteFormNew,
        },
        meta: { requerModal: true },
      },
      {
        path: ':id/alterar',
        name: 'pacientesAlterar',
        components: {
          lista: PacienteDataTable,
          formulario: PacienteFormEdit,
        },
        props: {
          formulario: true,
        },
        meta: { requerModal: true },
      },
      {
        path: ':id/excluir',
        name: 'pacientesExcluir',
        components: {
          lista: PacienteDataTable,
          formulario: PacienteDelete,
        },
        props: {
          formulario: true,
        },
        meta: { requerModal: true },
      },
    ],
  },
]

export default routes
