<template>
  <form-card-container
    titulo="Confirmar Exclusão"
    @cancelar="voltarParaLista"
    :loading="!paciente"
  >
    <template v-slot:extra>
      <v-card-subtitle>
        Tem certeza que deseja excluir este paciente?
      </v-card-subtitle>
    </template>
    <template v-slot:default>
      <v-form readonly data-cy="main-form" @submit.prevent="">
        <v-row dense>
          <v-col cols="12" sm="8">
            <v-text-field
              :value="paciente.nome"
              label="Nome Completo"
              data-cy="nome"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="2">
            <v-text-field
              :value="paciente.sexo | formatoGeneroAbreviado"
              label="Sexo"
              data-cy="sexo"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="2">
            <v-text-field
              :value="paciente.data_nascimento | formatoIdade"
              label="Idade"
              data-cy="idade"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              :value="paciente.rg"
              label="RG"
              data-cy="rg"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              :value="paciente.cpf | formatoCpfProtegido"
              label="CPF"
              data-cy="cpf"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              :value="paciente.sus | formatoCartaoSus"
              label="Cartão SUS"
              data-cy="sus"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:acao-principal>
      <botao-confirmar
        @click="excluir"
        :loading="isProcessing"
        :disabled="!paciente || isProcessing"
      />
    </template>
  </form-card-container>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { usePacienteStore } from '@/stores/paciente-store'
import { API_STATUS } from '@/utils/enums'
import filtrosFormatacaoMixin from '@/mixins/filtros-formatacao'
import FormCardContainer from '@/components/FormCardContainer.vue'
import BotaoConfirmar from '@/components/botoes/BotaoConfirmarExclusao.vue'
import { exibeMensagemSucesso } from '@/utils/toast-messenger'

export default {
  name: 'PacienteFormCardDelete',

  components: {
    FormCardContainer,
    BotaoConfirmar,
  },

  mixins: [filtrosFormatacaoMixin],

  props: ['id'],

  computed: {
    ...mapState(usePacienteStore, [
      'paciente',
      'readingStatus',
      'writingStatus',
    ]),

    isProcessing() {
      return this.writingStatus === API_STATUS.LOADING
    },
  },

  methods: {
    ...mapActions(usePacienteStore, ['fetchModel', 'destroyModel']),

    voltarParaLista() {
      this.$router.push({ name: 'pacientesLista' })
    },

    async excluir() {
      await this.destroyModel(this.id)
      if (this.writingStatus === API_STATUS.SUCCEEDED) {
        this.voltarParaLista()
        exibeMensagemSucesso('Paciente excluído com sucesso!')
      }
    },
  },

  async created() {
    await this.fetchModel(this.id)
    switch (this.readingStatus) {
      case API_STATUS.FAILED:
        this.voltarParaLista()
        break
    }
  },
}
</script>

<style></style>
