<template>
  <form-card-container
    titulo="Alterar Profissional"
    @cancelar="voltarParaLista"
    :exibeAlertaInvalido="!isFormValido"
    :loading="!modelForEdit"
  >
    <template v-slot:default>
      <v-form
        v-model="isFormValido"
        :disabled="isProcessing"
        @submit.prevent="salvar"
        data-cy="main-form"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="modelForEdit.nome"
              :rules="[rules.required]"
              label="Nome Completo"
              required
              autofocus
              data-cy="nome"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="modelForEdit.documento"
              label="Documento"
              hint="Informe o tipo do documento. Ex: CRM 1234-SP"
              data-cy="documento"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="modelForEdit.telefone"
              label="Telefone"
              placeholder="(00) 0000-0000"
              v-mask="maskTelefone"
              hint="digite apenas números"
              prepend-icon="mdi-phone"
              data-cy="telefone"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              label="Especialidades"
              v-model="modelForEdit.especialidades_ids"
              :items="lista_especialidades"
              :loading="isLoadingEspecialidades"
              item-text="titulo"
              item-value="id"
              chips
              deletable-chips
              multiple
              :menu-props="{ auto: true }"
              data-cy="especialidades"
            ></v-select>
          </v-col>
        </v-row>

        <input type="submit" hidden />
      </v-form>
    </template>
    <template v-slot:acao-principal>
      <botao-salvar
        @click="salvar"
        :loading="isProcessing"
        :disabled="!modelForEdit || isProcessing"
      />
    </template>
  </form-card-container>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useEspecialidadeStore } from '@/stores/especialidade-store'
import { useProfissionalStore } from '@/stores/profissional-store'
import { API_STATUS } from '@/utils/enums'
import FormCardContainer from '@/components/FormCardContainer.vue'
import BotaoSalvar from '@/components/botoes/BotaoSalvar.vue'
import validacoesComuns from '@/mixins/validacoes-comuns'
import { exibeMensagemSucesso } from '@/utils/toast-messenger'

export default {
  name: 'ProfissionalFormCardEdit',

  components: {
    FormCardContainer,
    BotaoSalvar,
  },

  mixins: [validacoesComuns],

  props: ['id'],

  computed: {
    ...mapState(useProfissionalStore, [
      'profissional',
      'readingStatus',
      'writingStatus',
    ]),
    ...mapState(useEspecialidadeStore, {
      lista_especialidades: 'especialidades',
      loading_especialidades: 'readingStatus',
    }),

    isProcessing() {
      return this.writingStatus === API_STATUS.LOADING
    },

    isLoadingEspecialidades() {
      return this.loading_especialidades === API_STATUS.LOADING
    },

    maskTelefone() {
      return (this.modelForEdit?.telefone?.length ?? 0) < 15
        ? '(##) ####-####?#'
        : '(##) #####-####'
    },
  },

  data() {
    return {
      isFormValido: false,
      modelForEdit: null,
    }
  },

  methods: {
    ...mapActions(useProfissionalStore, ['fetchModel', 'updateModel']),

    voltarParaLista() {
      this.$router.push({ name: 'profissionaisLista' })
    },

    async salvar() {
      if (this.isFormValido) {
        await this.updateModel(this.id, this.modelForEdit)
        if (this.writingStatus === API_STATUS.SUCCEEDED) {
          this.voltarParaLista()
          exibeMensagemSucesso('Profissional alterado com sucesso!')
        }
      }
    },
  },

  async created() {
    await this.fetchModel(this.id)
    switch (this.readingStatus) {
      case API_STATUS.FAILED:
        this.voltarParaLista()
        break

      case API_STATUS.SUCCEEDED:
        this.modelForEdit = JSON.parse(JSON.stringify(this.profissional))
        break
    }
  },
}
</script>

<style></style>
