<template>
  <form-card-container
    titulo="Confirmar Exclusão"
    @cancelar="voltarParaLista"
    :loading="!profissional"
  >
    <template v-slot:extra>
      <v-card-subtitle>
        Tem certeza que deseja excluir este profissional?
      </v-card-subtitle>
    </template>
    <template v-slot:default>
      <v-form readonly data-cy="main-form" @submit.prevent="">
        <v-row>
          <v-col cols="12">
            <v-text-field
              :value="profissional.nome"
              label="Nome Completo"
              data-cy="nome"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:acao-principal>
      <botao-confirmar
        @click="excluir"
        :loading="isProcessing"
        :disabled="!profissional || isProcessing"
      />
    </template>
  </form-card-container>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useProfissionalStore } from '@/stores/profissional-store'
import { API_STATUS } from '@/utils/enums'
import FormCardContainer from '@/components/FormCardContainer.vue'
import BotaoConfirmar from '@/components/botoes/BotaoConfirmarExclusao.vue'
import { exibeMensagemSucesso } from '@/utils/toast-messenger'

export default {
  name: 'ProfissionalFormCardDelete',

  components: {
    FormCardContainer,
    BotaoConfirmar,
  },

  props: ['id'],

  computed: {
    ...mapState(useProfissionalStore, [
      'profissional',
      'readingStatus',
      'writingStatus',
    ]),

    isProcessing() {
      return this.writingStatus === API_STATUS.LOADING
    },
  },

  methods: {
    ...mapActions(useProfissionalStore, ['fetchModel', 'destroyModel']),

    voltarParaLista() {
      this.$router.push({ name: 'profissionaisLista' })
    },

    async excluir() {
      await this.destroyModel(this.id)
      if (this.writingStatus === API_STATUS.SUCCEEDED) {
        this.voltarParaLista()
        exibeMensagemSucesso('Profissional excluído com sucesso!')
      }
    },
  },

  async created() {
    await this.fetchModel(this.id)
    switch (this.readingStatus) {
      case API_STATUS.FAILED:
        this.voltarParaLista()
        break
    }
  },
}
</script>

<style></style>
