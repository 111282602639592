<template>
  <v-btn
    text
    color="blue darken-1"
    @click="$emit('click')"
    data-cy="link-cancelar"
  >
    Cancelar
  </v-btn>
</template>

<script>
export default {
  name: 'BotaoCancelar',
}
</script>
