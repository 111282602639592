<template>
  <v-app>
    <main-navigation v-if="isAutenticado" data-cy="main-nav" />
    <v-main>
      <v-container v-if="isAutenticado" data-cy="main-content">
        <router-view />
      </v-container>
    </v-main>
    <router-view name="login" />
    <fullpage-authentication-overlay />
  </v-app>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth-store'
import MainNavigation from '@/components/MainNavigationDrawer.vue'
import FullpageAuthenticationOverlay from '@/components/FullpageAuthenticationOverlay.vue'

export default {
  name: 'App',

  components: {
    MainNavigation,
    FullpageAuthenticationOverlay,
  },

  computed: {
    ...mapState(useAuthStore, ['isAutenticado']),
  },

  mounted() {
    document.title = process.env.VUE_APP_TITLE
  },
}
</script>

<style>
/*--- Data Tables ---*/
.app-data-table.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  color: #34b8d5;
  font-size: 0.9rem;
  white-space: nowrap;
}
.app-data-table.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: 4px solid #34b8d577;
}
.app-data-table.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.app-data-table.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border-bottom: 0;
}
.app-data-table.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #c2ebf5;
}
.app-data-table .theme--light.v-btn .v-btn__content {
  filter: grayscale(1);
}
.app-data-table .theme--light.v-btn:hover::before {
  opacity: 1;
}
.app-data-table .theme--light.v-btn:hover .v-btn__content {
  color: #fff;
}
</style>
