import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// const PATTERN_DATA_ISO = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/
const PATTERN_DATA_DMA = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/

dayjs.extend(customParseFormat)

export default {
  filters: {
    /**
     * Aplica o formato CPF caso o valor de entrada tenha 11 caracteres.
     * @param {*} value Valor de entrada
     * @returns {*} Valor formatado: 000.000.000-00
     */
    formatoCpf(value) {
      if (value && value.length == 11) {
        return `${value.substring(0, 3)}.${value.substring(
          3,
          6
        )}.${value.substring(6, 9)}-${value.substring(9, 11)}`
      }
      return value
    },

    /**
     * Aplica o formato CPF parcial caso o valor de entrada tenha 11 caracteres.
     * @param {*} value Valor de entrada
     * @returns {*} Valor formatado: 000.***.***-00
     */
    formatoCpfProtegido(value) {
      if (value && value.length == 11) {
        return `${value.substring(0, 3)}.***.***-${value.substring(9, 11)}`
      }
      return value
    },

    /**
     * Aplica o formato CEP caso o valor de entrada tenha 8 caracteres.
     * @param {*} value Valor de entrada
     * @returns {*} Valor formatado: 00000-000
     */
    formatoCep(value) {
      if (value && value.length == 8) {
        return `${value.substring(0, 5)}-${value.substring(5, 8)}`
      }
      return value
    },

    /**
     * Aplica o formato de data DD/MM/YYYY caso o valor de entrada seja uma data válida.
     * @param {*} value Valor de entrada (formato ISO)
     * @returns {*} Valor formatado: 31/12/2099
     */
    formatoData(value) {
      if (value && value.length === 10) {
        const dateValue = dayjs(value)
        if (dateValue.isValid()) {
          return dateValue.format('DD/MM/YYYY')
        }
      }
      return value
    },

    /**
     * Aplica o formato de data YYYY-MM-DD caso o valor de entrada seja uma data válida.
     * @param {*} value Valor de entrada (formato D/M/Y)
     * @returns {*} Valor formatado: 31/12/2099
     */
    formatoDataIso(value) {
      if (value && value.length === 10) {
        const dateValue = dayjs(value, 'DD/MM/YYYY', true)
        if (dateValue.isValid()) {
          return dateValue.format('YYYY-MM-DD')
        }
      }
      return value
    },

    /**
     * Retorna a idade em anos caso o valor de entrada seja uma data válida, anterior a data atual.
     * Caso a idade seja inválida, retorna vazio.
     * @param {*} value Valor de entrada (formato ISO ou DD/MM/YYYY)
     * @returns {*} Idade formatada: xx anos
     */
    formatoIdade(value) {
      if (value && value.length === 10) {
        const dateValue = PATTERN_DATA_DMA.test(value)
          ? dayjs(value, 'DD/MM/YYYY', true)
          : dayjs(value)
        if (dateValue.isValid() && dateValue.isBefore()) {
          return dayjs().diff(dateValue, 'year') + ' anos'
        }
      }
      return ''
    },

    /**
     * Abrevia o gênero de acordo com a sigla.
     * @param {*} value Valor de entrada (M|F)
     * @returns {*} Valor formatado: Masc.|Fem.
     */
    formatoGeneroAbreviado(value) {
      if (value === 'M') return 'Masc.'
      if (value === 'F') return 'Fem.'
      return value
    },

    /**
     * Aplica o formato de cartão do SUS caso o valor de entrada tenha 15 caracteres.
     * @param {*} value Valor de entrada
     * @returns {*} Valor formatado: 000 0000 0000 0000
     */
    formatoCartaoSus(value) {
      if (value && value.length == 15) {
        return `${value.substring(0, 3)} ${value.substring(
          3,
          7
        )} ${value.substring(7, 11)} ${value.substring(11, 15)}`
      }
      return value
    },
  },
}
