import ListaView from '@/views/ListaCrudView.vue'
import FormPlaceholderView from '@/components/FormPlaceholder.vue'
import ProfissionalDataTable from '@/components/profissionais/ProfissionalDataTable.vue'
import ProfissionalFormNew from '@/components/profissionais/ProfissionalFormCardNew.vue'
import ProfissionalFormEdit from '@/components/profissionais/ProfissionalFormCardEdit.vue'
import ProfissionalDelete from '@/components/profissionais/ProfissionalFormCardDelete.vue'

const routes = [
  {
    path: '/profissionais',
    component: ListaView,
    props: {
      tituloSecao: 'Profissionais',
      rotuloNovo: 'Novo Profissional',
      routeNovo: 'profissionaisInserir',
    },
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'profissionaisLista',
        components: {
          lista: ProfissionalDataTable,
          formulario: FormPlaceholderView,
        },
      },
      {
        path: 'inserir',
        name: 'profissionaisInserir',
        components: {
          lista: ProfissionalDataTable,
          formulario: ProfissionalFormNew,
        },
        meta: { requerModal: true },
      },
      {
        path: ':id/alterar',
        name: 'profissionaisAlterar',
        components: {
          lista: ProfissionalDataTable,
          formulario: ProfissionalFormEdit,
        },
        props: {
          formulario: true,
        },
        meta: { requerModal: true },
      },
      {
        path: ':id/excluir',
        name: 'profissionaisExcluir',
        components: {
          lista: ProfissionalDataTable,
          formulario: ProfissionalDelete,
        },
        props: {
          formulario: true,
        },
        meta: { requerModal: true },
      },
    ],
  },
]

export default routes
