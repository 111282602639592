import Vue from 'vue'
import VueRouter from 'vue-router'
import routesBase from './routes-base'
import routesEspecialidades from './routes-especialidades'
import routesProfissionais from './routes-profissionais'
import routesPacientes from './routes-pacientes'

Vue.use(VueRouter)

const routes = [
  ...routesBase,
  ...routesEspecialidades,
  ...routesProfissionais,
  ...routesPacientes,
]

const router = new VueRouter({ routes })
export default router
