<template>
  <v-btn
    color="primary"
    class="ml-2"
    @click="$emit('click')"
    :to="to"
    data-cy="link-novo"
  >
    <v-icon left class="d-none d-sm-flex">mdi-plus-circle</v-icon>
    <span class="d-none d-sm-flex">{{ rotulo }}</span>
    <span class="d-sm-none">{{ rotuloMobile }}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'BotaoNovo',
  props: {
    rotulo: {
      type: String,
      default: 'Novo',
      required: true,
    },
    rotuloMobile: {
      type: String,
      default: 'Novo',
    },
    to: Object,
  },
}
</script>
