import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Response } from 'miragejs'

dayjs.extend(utc)

const CHAVE_USUARIO_LOGADO = 'mock-logado'

export default function (server) {
  server.config({
    routes() {
      this.urlPrefix = process.env.VUE_APP_API_URL
      this.timing = 500

      /**
       * Rotas 'resource', operações crud
       * -------------------------------------------------
       * GET    /api/...
       * GET    /api/.../:id
       * POST   /api/...
       * PUT    /api/.../:id
       * DELETE /api/.../:id
       */
      this.resource('especialidades', { path: '/api/especialidades' })
      this.resource('profissionals', { path: '/api/profissionais' })
      this.resource('pacientes', { path: '/api/pacientes' })

      /**
       * Rotas de autenticação
       * -------------------------------------------------
       * GET    /sanctum/csrf-cookie
       * POST   /login
       * POST   /logout
       * GET    /api/autenticado
       */
      this.get('/sanctum/csrf-cookie', () => {
        // Adiciona o token esperado pelo Sanctum
        // ref - https://miragejs.com/docs/advanced/simulating-cookie-responses/
        const cookieExpiration = dayjs().add(10, 'minute').utc().format()
        document.cookie = `XSRF-TOKEN=faKeCOokIeVAluE; domain=localhost; path=/; expires=${cookieExpiration};`
        return new Response(204)
      })

      this.post('/login', (schema, request) => {
        const postData = JSON.parse(request.requestBody)
        const usuario = schema.usuarios.findBy({
          name: postData.name,
          password: postData.password,
        })

        if (usuario) {
          localStorage.setItem(CHAVE_USUARIO_LOGADO, JSON.stringify(usuario))
          return new Response(204)
        }
        return new Response(422, {}, { message: 'Dados inválidos' })
      })

      this.post('/logout', () => {
        localStorage.removeItem(CHAVE_USUARIO_LOGADO)
      })

      this.get('/api/autenticado', () => {
        // Utiliza local storage para simular a 'sessão' no servidor
        const usuario = localStorage.getItem(CHAVE_USUARIO_LOGADO)
        return usuario == null ? new Response(401) : JSON.parse(usuario)
      })

      // Outras rotas não são interceptadas
      // this.passthrough()
    },
  })
}
