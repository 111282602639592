<template>
  <form-card-container
    titulo="Alterar Especialidade"
    @cancelar="voltarParaLista"
    :exibeAlertaInvalido="!isFormValido"
    :loading="!modelForEdit"
  >
    <template v-slot:default>
      <v-form
        v-model="isFormValido"
        :disabled="isProcessing"
        @submit.prevent="salvar"
        data-cy="main-form"
      >
        <v-text-field
          v-model="modelForEdit.titulo"
          :rules="[rules.required]"
          label="Título"
          required
          autofocus
          data-cy="titulo"
        ></v-text-field>

        <v-select
          label="Profissionais Associados"
          v-model="modelForEdit.profissionais_ids"
          :items="lista_profissionais"
          :loading="isLoadingProfissionais"
          item-text="nome"
          item-value="id"
          chips
          deletable-chips
          multiple
          :menu-props="{ auto: true }"
          data-cy="profissionais"
        ></v-select>

        <input type="submit" hidden />
      </v-form>
    </template>
    <template v-slot:acao-principal>
      <botao-salvar
        @click="salvar"
        :loading="isProcessing"
        :disabled="!modelForEdit || isProcessing"
      />
    </template>
  </form-card-container>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useEspecialidadeStore } from '@/stores/especialidade-store'
import { useProfissionalStore } from '@/stores/profissional-store'
import { API_STATUS } from '@/utils/enums'
import FormCardContainer from '@/components/FormCardContainer.vue'
import BotaoSalvar from '@/components/botoes/BotaoSalvar.vue'
import validacoesComuns from '@/mixins/validacoes-comuns'
import { exibeMensagemSucesso } from '@/utils/toast-messenger'

export default {
  name: 'EspecialidadeFormCardEdit',

  components: {
    FormCardContainer,
    BotaoSalvar,
  },

  mixins: [validacoesComuns],

  props: ['id'],

  computed: {
    ...mapState(useEspecialidadeStore, [
      'especialidade',
      'readingStatus',
      'writingStatus',
    ]),
    ...mapState(useProfissionalStore, {
      lista_profissionais: 'profissionais',
      loading_profissionais: 'readingStatus',
    }),

    isProcessing() {
      return this.writingStatus === API_STATUS.LOADING
    },

    isLoadingProfissionais() {
      return this.loading_profissionais === API_STATUS.LOADING
    },
  },

  data() {
    return {
      isFormValido: false,
      modelForEdit: null,
    }
  },

  methods: {
    ...mapActions(useEspecialidadeStore, ['fetchModel', 'updateModel']),

    voltarParaLista() {
      this.$router.push({ name: 'especialidadesLista' })
    },

    async salvar() {
      if (this.isFormValido) {
        await this.updateModel(this.id, this.modelForEdit)
        if (this.writingStatus === API_STATUS.SUCCEEDED) {
          this.voltarParaLista()
          exibeMensagemSucesso('Especialidade alterada com sucesso!')
        }
      }
    },
  },

  async created() {
    await this.fetchModel(this.id)
    switch (this.readingStatus) {
      case API_STATUS.FAILED:
        this.voltarParaLista()
        break

      case API_STATUS.SUCCEEDED:
        this.modelForEdit = JSON.parse(JSON.stringify(this.especialidade))
        break
    }
  },
}
</script>

<style></style>
