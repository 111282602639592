<template>
  <v-card data-cy="form-loading" v-if="loading">
    <v-progress-circular
      class="d-block mx-auto my-10"
      indeterminate
      size="50"
      color="light-blue"
    ></v-progress-circular>
  </v-card>
  <v-card data-cy="form-card" v-else>
    <v-card-title>
      <span class="text-h5">{{ titulo }}</span>

      <v-tooltip
        right
        color="warning"
        v-if="exibeAlertaInvalido"
        data-cy="alerta-form"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            color="warning"
            size="30"
            class="mx-2 shake"
          >
            mdi-alert
          </v-icon>
        </template>
        <span
          >O formulário possui<br />
          campos inválidos.</span
        >
      </v-tooltip>

      <v-spacer></v-spacer>
      <botao-cancelar-mini @click="$emit('cancelar')" />
    </v-card-title>

    <slot name="extra"></slot>

    <v-card-text>
      <slot></slot>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <botao-cancelar @click="$emit('cancelar')" />
      <slot name="acao-principal"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import BotaoCancelarMini from '@/components/botoes/BotaoCancelarMini.vue'
import BotaoCancelar from '@/components/botoes/BotaoCancelar.vue'

export default {
  name: 'FormCardContainer',

  components: {
    BotaoCancelarMini,
    BotaoCancelar,
  },

  props: {
    titulo: {
      type: String,
      required: true,
      default: 'Cadastro',
    },

    exibeAlertaInvalido: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.shake {
  animation: shaking 2s infinite;
}

@keyframes shaking {
  0% {
    transform: translateX(0);
  }
  5% {
    transform: translateX(5px);
  }
  10% {
    transform: translateX(-5px);
  }
  15% {
    transform: translateX(5px);
  }
  20% {
    transform: translateX(-5px);
  }
  25% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
