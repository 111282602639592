/**
 * Refs:
 *
 * https://axios-http.com/docs/interceptors
 * https://www.npmjs.com/package/axios#request-config
 * https://www.npmjs.com/package/axios#response-schema
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
 */

const getCRSFToken = () => {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith('XSRF-TOKEN='))
    ?.split('=')[1]
}

/**
 * Faz uma chamada ao endpoint responsável por gerar o token CRSF.
 * @returns
 */
const setCSRFToken = (axiosClient) => {
  return axiosClient.get('/sanctum/csrf-cookie')
}

const addAuthInterceptors = (axiosClient) => {
  // Confere o token do Sanctum, se não existir solicita um novo
  const handleRequestFulfilled = async (config) => {
    if (
      (config.method == 'post' ||
        config.method == 'put' ||
        config.method == 'patch' ||
        config.method == 'delete') &&
      !getCRSFToken('XSRF-TOKEN')
    ) {
      try {
        await setCSRFToken(axiosClient)
      } catch (error) {
        Promise.reject(error)
      }
    }

    return config
  }

  // Tratamento para erros 401 (não autenticado ou acesso expirado) // TODO: teste prático
  const handleResponseRejected = (error) => {
    if (error.response && error.response.status === 401) {
      // TODO: tratamento 401 (axios interceptor), remover "eslint-disable..."
    }

    return Promise.reject(error)
  }

  axiosClient.interceptors.request.use(handleRequestFulfilled, undefined)
  axiosClient.interceptors.response.use(undefined, handleResponseRejected)
}

export { addAuthInterceptors, getCRSFToken }
