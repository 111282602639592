<template>
  <form-card-container
    titulo="Nova Especialidade"
    @cancelar="voltarParaLista"
    :exibeAlertaInvalido="!isFormValido"
  >
    <template v-slot:default>
      <v-form
        ref="formNovo"
        v-model="isFormValido"
        :disabled="isProcessing"
        @submit.prevent="salvar"
        data-cy="main-form"
        lazy-validation
      >
        <v-text-field
          v-model="modelForInsert.titulo"
          :rules="[rules.required]"
          label="Título"
          required
          autofocus
          data-cy="titulo"
        ></v-text-field>

        <v-select
          label="Profissionais Associados"
          v-model="modelForInsert.profissionais_ids"
          :items="lista_profissionais"
          item-text="nome"
          item-value="id"
          chips
          deletable-chips
          multiple
          :menu-props="{ auto: true }"
          data-cy="profissionais"
        ></v-select>

        <input type="submit" hidden />
      </v-form>
    </template>
    <template v-slot:acao-principal>
      <botao-salvar
        @click="salvar"
        :loading="isProcessing"
        :disabled="isProcessing"
      />
    </template>
  </form-card-container>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useEspecialidadeStore } from '@/stores/especialidade-store'
import { useProfissionalStore } from '@/stores/profissional-store'
import { API_STATUS } from '@/utils/enums'
import FormCardContainer from '@/components/FormCardContainer.vue'
import BotaoSalvar from '@/components/botoes/BotaoSalvar.vue'
import validacoesComuns from '@/mixins/validacoes-comuns'
import { exibeMensagemSucesso } from '@/utils/toast-messenger'

export default {
  name: 'EspecialidadeFormCardNew',

  components: {
    BotaoSalvar,
    FormCardContainer,
  },

  mixins: [validacoesComuns],

  computed: {
    ...mapState(useEspecialidadeStore, ['writingStatus']),
    ...mapState(useProfissionalStore, {
      lista_profissionais: 'profissionais',
      loading_profissionais: 'readingStatus',
    }),

    isProcessing() {
      return this.writingStatus === API_STATUS.LOADING
    },

    isLoadingProfissionais() {
      return this.loading_profissionais === API_STATUS.LOADING
    },
  },

  data() {
    return {
      isFormValido: false,
      modelForInsert: {},
    }
  },

  methods: {
    ...mapActions(useEspecialidadeStore, ['createModel']),

    voltarParaLista() {
      this.$router.push({ name: 'especialidadesLista' })
    },

    async salvar() {
      // Precisa chamar o 'validate' devido ao lazy-validation do form
      if (this.$refs.formNovo.validate()) {
        await this.createModel(this.modelForInsert)
        if (this.writingStatus === API_STATUS.SUCCEEDED) {
          this.voltarParaLista()
          exibeMensagemSucesso('Especialidade inserida com sucesso!')
        }
      }
    },
  },
}
</script>

<style></style>
