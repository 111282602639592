<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          v-model="busca"
          append-icon="mdi-magnify"
          label="Pesquisar"
          placeholder="pesquisar por nome ou documento"
          single-line
          hide-details
          outlined
          dense
          clearable
          role="searchbox"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      class="app-data-table"
      :headers="tableHeaders"
      :items="lista_profissionais"
      :loading="isLoading"
      :search="busca"
      :items-per-page="5"
      data-cy="main-data-table"
    >
      <template v-slot:[`item.especialidades_ids`]="{ item }">
        <div class="d-relative">
          <v-chip
            class="mr-2"
            color="cyan darken-3"
            outlined
            :key="especialidade.id"
            v-for="especialidade in filtraListaEspecialidades(
              item.especialidades_ids
            )"
            >{{ especialidade.titulo }}</v-chip
          >
        </div>
      </template>
      <template v-slot:[`item.crud_actions`]="{ item }">
        <botao-alterar-mini
          :to="{ name: 'profissionaisAlterar', params: { id: item.id } }"
          class="mr-2"
        />
        <botao-excluir-mini
          :to="{ name: 'profissionaisExcluir', params: { id: item.id } }"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useEspecialidadeStore } from '@/stores/especialidade-store'
import { useProfissionalStore } from '@/stores/profissional-store'
import { API_STATUS } from '@/utils/enums'
import BotaoAlterarMini from '@/components/botoes/BotaoAlterarMini.vue'
import BotaoExcluirMini from '@/components/botoes/BotaoExcluirMini.vue'
import helperDadosRelacionados from '@/mixins/helper-dados-relacionados'

export default {
  name: 'ProfissionalDataTable',

  components: {
    BotaoAlterarMini,
    BotaoExcluirMini,
  },

  mixins: [helperDadosRelacionados],

  data() {
    return {
      busca: '',
      tableHeaders: [
        { text: 'Nome', value: 'nome' },
        { text: 'Documento', value: 'documento' },
        {
          text: 'Especialidades',
          value: 'especialidades_ids',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Ações',
          value: 'crud_actions',
          sortable: false,
          filterable: false,
          class: 'header-crud-action',
          cellClass: 'cel-crud-action',
        },
      ],
    }
  },

  computed: {
    ...mapState(useProfissionalStore, {
      lista_profissionais: 'profissionais',
      loading_profissionais: 'readingStatus',
      updating_profissionais: 'writingStatus',
    }),
    ...mapState(useEspecialidadeStore, {
      lista_especialidades: 'especialidades',
      loading_especialidades: 'readingStatus',
    }),

    isLoading() {
      return (
        this.loading_profissionais === API_STATUS.LOADING ||
        this.loading_especialidades === API_STATUS.LOADING
      )
    },
  },

  methods: {
    ...mapActions(useProfissionalStore, { fetchProfissionais: 'fetchModels' }),
    ...mapActions(useEspecialidadeStore, {
      fetchEspecialidades: 'fetchModels',
    }),

    carregarDados() {
      this.fetchProfissionais()
      this.fetchEspecialidades()
    },

    filtraListaEspecialidades(idsEspecialidades) {
      return this.filtraListaRelacionada(
        idsEspecialidades,
        this.lista_especialidades
      )
    },
  },

  created() {
    this.carregarDados()
  },

  watch: {
    updating_profissionais: function () {
      // verifica se houve mudança nos dados para atualizar a lista
      if (this.updating_profissionais === API_STATUS.SUCCEEDED) {
        this.carregarDados()
      }
    },
  },
}
</script>

<style>
.header-crud-action {
  width: 150px;
}
.cel-crud-action {
  white-space: nowrap;
}
.d-relative {
  position: relative;
  min-height: 1rem;
}
.loading-image {
  position: absolute;
  left: 0;
}
</style>
