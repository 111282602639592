<template>
  <v-btn
    color="primary"
    @click="$emit('click')"
    :loading="loading"
    :disabled="disabled"
    data-cy="submit"
  >
    <v-icon left>mdi-content-save</v-icon>
    Salvar
  </v-btn>
</template>

<script>
export default {
  name: 'BotaoSalvar',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
