<template>
  <v-navigation-drawer
    app
    color="navSidebarBg"
    class="elevation-4"
    dark
    permanent
    :mini-variant="isMobile"
  >
    <router-link :to="{ name: 'home' }">
      <v-img
        src="@/assets/logo-200px.png"
        alt="Logo Saúde Essencial"
        contain
        max-width="200"
        class="mx-auto my-6"
        v-if="!isMobile"
      />
      <v-img
        src="@/assets/logo-64px.png"
        alt="Logo Saúde Essencial"
        class="my-3 mx-1"
        contain
        v-if="isMobile"
      />
    </router-link>

    <v-divider></v-divider>

    <v-list-group class="menu-usuario">
      <template v-slot:activator>
        <v-list-item-avatar>
          <v-img src="@/assets/avatar-placeholder.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title data-cy="usuario-nome">{{
            getNomeExibicao
          }}</v-list-item-title>
          <v-list-item-subtitle class="text-caption" data-cy="usuario-role">
            {{ rotuloNivelAcesso }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <v-list nav dense>
        <v-list-item disabled>
          <v-list-item-icon>
            <span class="mx-auto">
              <font-awesome-icon icon="fa-user"></font-awesome-icon>
            </span>
          </v-list-item-icon>
          <v-list-item-title>Meus Dados</v-list-item-title>
        </v-list-item>
        <v-list-item @click="encerrarSessao" data-cy="logout">
          <v-list-item-icon>
            <span class="mx-auto">
              <font-awesome-icon
                icon="fa-right-from-bracket"
              ></font-awesome-icon>
            </span>
          </v-list-item-icon>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-list-group>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item
        v-for="item in menuLinks"
        :key="item.title"
        :to="item.link"
        :disabled="!item.link"
        link
        data-cy="menu-link"
      >
        <v-list-item-icon>
          <span class="mx-auto">
            <font-awesome-icon :icon="item.icon"></font-awesome-icon>
          </span>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth-store'

export default {
  name: 'MainNavigationDrawer',
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },

    rotuloNivelAcesso() {
      if (this.isAdministrador) return 'Administrador(a)'
      if (this.isOperador) return 'Operador(a)'
      if (this.isProfissional) return 'Profissional'
      return ''
    },

    menuLinks() {
      const lkPainel = {
        title: 'Painel',
        icon: 'fa-gauge-high',
        link: { name: 'painel' },
      }
      const lkPacientes = {
        title: 'Pacientes',
        icon: 'fa-head-side-mask',
        link: { name: 'pacientesLista' },
      }
      const lkProfissionais = {
        title: 'Profissionais',
        icon: 'fa-user-doctor',
        link: { name: 'profissionaisLista' },
      }
      const lkEspecialidades = {
        title: 'Especialidades',
        icon: 'fa-briefcase-medical',
        link: { name: 'especialidadesLista' },
      }
      const lkPlantoes = {
        title: 'Plantões',
        icon: 'fa-clock',
        link: '',
      }
      const lkAgendamentos = {
        title: 'Agendamentos',
        icon: 'fa-calendar-check',
        link: '',
      }
      const lkAtendimentos = {
        title: 'Atendimentos',
        icon: 'fa-notes-medical',
        link: '',
      }
      const lkRelatorios = {
        title: 'Relatórios',
        icon: 'fa-chart-pie',
        link: '',
      }
      const lkUsuarios = {
        title: 'Usuários',
        icon: 'fa-users',
        link: '',
      }

      let links = [lkPainel]

      if (this.isAdministrador || this.isOperador) links.push(lkPacientes)
      if (this.isAdministrador || this.isOperador) links.push(lkProfissionais)
      if (this.isAdministrador || this.isOperador) links.push(lkEspecialidades)
      if (this.isAdministrador || this.isOperador) links.push(lkPlantoes)
      if (this.isAdministrador || this.isOperador || this.isProfissional)
        links.push(lkAgendamentos)
      if (this.isAdministrador || this.isOperador || this.isProfissional)
        links.push(lkAtendimentos)
      if (this.isAdministrador) links.push(lkRelatorios)
      if (this.isAdministrador) links.push(lkUsuarios)

      return links
    },

    ...mapState(useAuthStore, [
      'getNomeExibicao',
      'isAdministrador',
      'isOperador',
      'isProfissional',
    ]),
  },
  methods: {
    ...mapActions(useAuthStore, ['logout']),

    async encerrarSessao() {
      await this.logout()
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style>
.menu-usuario .v-list-group__header.v-list-item {
  padding-right: 8px;
  padding-left: 8px;
}
.menu-usuario.v-list-group--active.primary--text
  .v-list-group__header
  .v-list-item__title {
  color: #fff;
}
</style>
