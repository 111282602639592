import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import pt from 'vuetify/lib/locale/pt'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.green.darken2,
        navSidebarBg: '#0d2e3a',
      },
    },
  },

  lang: {
    locales: { pt },
    current: 'pt',
  },
})
