import { defineStore } from 'pinia'
import { API_STATUS } from '@/utils/enums'
import { exibeMensagemErroAxios } from '@/utils/toast-messenger'
import apiClient from '@/utils/api-client'

export const useProfissionalStore = defineStore('profissional', {
  state: () => {
    return {
      profissionais: [],
      profissional: null,
      writingStatus: API_STATUS.IDLE,
      readingStatus: API_STATUS.IDLE,
    }
  },

  getters: {},

  actions: {
    /**
     * Consulta todos os profissionais.
     */
    async fetchModels() {
      this.readingStatus = API_STATUS.LOADING
      try {
        const response = await apiClient.get(`/api/profissionais`)
        this.profissionais = response.data.data
        this.readingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.readingStatus = API_STATUS.FAILED
      }
    },

    /**
     * Consulta um profissional.
     * @param {String} id
     */
    async fetchModel(id) {
      this.readingStatus = API_STATUS.LOADING
      this.profissional = null
      try {
        const response = await apiClient.get(`/api/profissionais/${id}`)
        this.profissional = response.data.data
        this.readingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.readingStatus = API_STATUS.FAILED
      }
    },

    /**
     * Insere um novo profissional.
     * @param {Object} payload
     */
    async createModel(payload) {
      this.writingStatus = API_STATUS.LOADING
      try {
        const dados = JSON.stringify(payload)
        await apiClient.post(`/api/profissionais`, dados)
        this.writingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.writingStatus = API_STATUS.FAILED
      }
    },

    /**
     * Atualiza os dados de um profissional.
     * @param {String} id
     * @param {Object} payload
     */
    async updateModel(id, payload) {
      this.writingStatus = API_STATUS.LOADING
      try {
        const dados = JSON.stringify(payload)
        await apiClient.put(`/api/profissionais/${id}`, dados)
        this.writingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.writingStatus = API_STATUS.FAILED
      }
    },

    /**
     * Exclui um profissional.
     * @param {String} id
     */
    async destroyModel(id) {
      this.writingStatus = API_STATUS.LOADING
      try {
        await apiClient.delete(`/api/profissionais/${id}`)
        this.writingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.writingStatus = API_STATUS.FAILED
      }
    },
  },
})
