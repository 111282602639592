<template>
  <v-btn
    color="error"
    @click="$emit('click')"
    :loading="loading"
    :disabled="disabled"
    data-cy="submit"
  >
    <v-icon left>mdi-delete</v-icon>
    Confirmar
  </v-btn>
</template>

<script>
export default {
  name: 'BotaoConfirmarExclusao',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
