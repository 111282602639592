import { defineStore } from 'pinia'
import { API_STATUS } from '@/utils/enums'
import { exibeMensagemErroAxios } from '@/utils/toast-messenger'
import apiClient from '@/utils/api-client'

export const usePacienteStore = defineStore('paciente', {
  state: () => {
    return {
      pacientes: [],
      paciente: null,
      writingStatus: API_STATUS.IDLE,
      readingStatus: API_STATUS.IDLE,
    }
  },

  getters: {},

  actions: {
    /**
     * Consulta todos os pacientes.
     */
    async fetchModels() {
      this.readingStatus = API_STATUS.LOADING
      try {
        const response = await apiClient.get(`/api/pacientes`)
        this.pacientes = response.data.data
        this.readingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.readingStatus = API_STATUS.FAILED
      }
    },

    /**
     * Consulta um paciente.
     * @param {String} id
     */
    async fetchModel(id) {
      this.readingStatus = API_STATUS.LOADING
      this.paciente = null
      try {
        const response = await apiClient.get(`/api/pacientes/${id}`)
        this.paciente = response.data.data
        this.readingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.readingStatus = API_STATUS.FAILED
      }
    },

    /**
     * Insere um novo paciente.
     * @param {Object} payload
     */
    async createModel(payload) {
      this.writingStatus = API_STATUS.LOADING
      try {
        const dados = JSON.stringify(payload)
        await apiClient.post(`/api/pacientes`, dados)
        this.writingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.writingStatus = API_STATUS.FAILED
      }
    },

    /**
     * Atualiza os dados de um paciente.
     * @param {String} id
     * @param {Object} payload
     */
    async updateModel(id, payload) {
      this.writingStatus = API_STATUS.LOADING
      try {
        const dados = JSON.stringify(payload)
        await apiClient.put(`/api/pacientes/${id}`, dados)
        this.writingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.writingStatus = API_STATUS.FAILED
      }
    },

    /**
     * Exclui um paciente.
     * @param {String} id
     */
    async destroyModel(id) {
      this.writingStatus = API_STATUS.LOADING
      try {
        await apiClient.delete(`/api/pacientes/${id}`)
        this.writingStatus = API_STATUS.SUCCEEDED
      } catch (error) {
        exibeMensagemErroAxios(error)
        this.writingStatus = API_STATUS.FAILED
      }
    },
  },
})
