<template>
  <div>
    <header-secao titulo="Painel"> </header-secao>
  </div>
</template>

<script>
import HeaderSecao from '@/components/HeaderSecao.vue'

export default {
  components: { HeaderSecao },
}
</script>

<style></style>
