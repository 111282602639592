/**
 * Retorna uma mensagem de erro amigável para um erros de comunicação
 * com a API vindos do Axios.
 * @param {*} error
 * @returns {String} Mensagem de erro amigável.
 */
export default (error) => {
  if (!error) return ''

  const statusCode = error?.response?.status
  if (statusCode === 401) return 'Erro 401 - Acesso não autenticado'
  if (statusCode === 403) return 'Erro 403 - Acesso restrito'
  if (statusCode === 404) return 'Erro 404 - Não encontrado'
  if (statusCode === 422) return 'Erro 422 - Dados inválidos' // TODO: revisar tratamento 422

  if (error?.response?.data?.message !== undefined)
    return error.response.data.message
  return error.message ?? 'Erro desconhecido'
}
