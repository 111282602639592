import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import pinia from './plugins/pinia'
import './plugins/font-awesome'
import './plugins/vue-toastification'
import './plugins/v-mask'
import './mock/mirage-config'
import { applyAuthGuard } from '@/router/guards'
import { useAuthStore } from '@/stores/auth-store'

// Vue.config.productionTip = false

new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')

if (window.Cypress) {
  window.store = useAuthStore()
}

// Aplica as guards depois do pinia ser instalado.
// Evita erro - 'getActivePinia was called with no active Pinia'
applyAuthGuard(router) // FIXME - não funciona no primeiro load, beforeEach inativo
