/*
Modelo JSON - https://json-generator.com/
[
  '{{repeat(12)}}',
  {
    id: '{{index(1)}}',
    titulo: '{{lorem(1, "words")}}',
  }
]
*/
export default [
  {
    id: 1,
    titulo: 'Occaecat',
    profissionais_ids: [
      'dce2a5f8-f755-4855-b697-96d2d48eddb0',
      'c92e0dd9-0829-43c7-81ce-c044df1127db',
      '3a46e231-f1f1-4a8b-bc6e-a23e50c02dff',
    ],
  },
  {
    id: 2,
    titulo: 'Ullamcorper',
  },
  {
    id: 3,
    titulo: 'Maecenas',
    profissionais_ids: [
      'dce2a5f8-f755-4855-b697-96d2d48eddb0',
      '2bef3bf4-45df-4c08-8206-3319af3c739d',
      'f8f2f3b2-4b99-45cd-bc37-c00e81b214f4',
    ],
  },
  {
    id: 4,
    titulo: 'Excepteur',
    profissionais_ids: ['3a46e231-f1f1-4a8b-bc6e-a23e50c02dff'],
  },
  {
    id: 5,
    titulo: 'Praesentex',
    profissionais_ids: [
      'c92e0dd9-0829-43c7-81ce-c044df1127db',
      'f8f2f3b2-4b99-45cd-bc37-c00e81b214f4',
    ],
  },
  {
    id: 6,
    titulo: 'Minim commodo',
  },
  {
    id: 7,
    titulo: 'Phasellus',
    profissionais_ids: ['2bef3bf4-45df-4c08-8206-3319af3c739d'],
  },
  {
    id: 8,
    titulo: 'Suspendisse',
    profissionais_ids: [
      '2ed9d8aa-06c3-464b-aa85-a118a224d29a',
      'f8f2f3b2-4b99-45cd-bc37-c00e81b214f4',
    ],
  },
  {
    id: 9,
    titulo: 'Cillum',
    profissionais_ids: [
      '109e21ea-10f1-40a9-9d0f-a5fdfe5c20da',
      'f8f2f3b2-4b99-45cd-bc37-c00e81b214f4',
    ],
  },
  {
    id: 10,
    titulo: 'Nulla facilisi',
    profissionais_ids: ['dce2a5f8-f755-4855-b697-96d2d48eddb0'],
  },
  {
    id: 11,
    titulo: 'Venenatis',
  },
  {
    id: 12,
    titulo: 'Aliquam',
    profissionais_ids: [
      '2ed9d8aa-06c3-464b-aa85-a118a224d29a',
      '109e21ea-10f1-40a9-9d0f-a5fdfe5c20da',
    ],
  },
]
