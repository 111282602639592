<template>
  <v-app-bar
    elevation="3"
    class="main-header"
    fixed
    app
    elevate-on-scroll
    data-cy="header-secao"
  >
    <v-toolbar-title>{{ titulo }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <slot></slot>
  </v-app-bar>
</template>

<script>
export default {
  name: 'HeaderSecao',

  props: {
    titulo: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.main-header {
  background-color: #fff !important;
  border-bottom: 1px solid rgb(220, 220, 220);
}
</style>
