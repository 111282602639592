import Vue from 'vue'

/* add fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* add some free styles */
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'
import {
  faGaugeHigh,
  faHeadSideMask,
  faUserDoctor,
  faBriefcaseMedical,
  faClock,
  faCalendarCheck,
  faNotesMedical,
  faPieChart,
  faUser,
  faUsers,
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons'

/* add each imported icon to the library */
// library.add(fas, far)
library.add(
  faGaugeHigh,
  faHeadSideMask,
  faUserDoctor,
  faBriefcaseMedical,
  faClock,
  faCalendarCheck,
  faNotesMedical,
  faPieChart,
  faUser,
  faUsers,
  faRightFromBracket
)

/* add the component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)
