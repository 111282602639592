import { useAuthStore } from '@/stores/auth-store'

export const applyAuthGuard = (router) => {
  router.beforeEach((to, from, next) => {
    // Se a rota requer autenticação (requiresAuth) e não está logado,
    // redireciona para a rota de login.
    // Se a rota não permite autenticação (guest) mas está logado,
    // redireciona para o painel inicial.

    const authStore = useAuthStore()

    if (
      to.matched.some((record) => record.meta.requiresAuth) &&
      !authStore.isAutenticado
    ) {
      next({ name: 'login' })
    } else if (
      to.matched.some((record) => record.meta.guest) &&
      authStore.isAutenticado
    ) {
      next({ name: 'painel' })
    }

    next()
  })
}
