import ListaView from '@/views/ListaCrudView.vue'
import FormPlaceholderView from '@/components/FormPlaceholder.vue'
import EspecialidadeDataTable from '@/components/especialidades/EspecialidadeDataTable.vue'
import EspecialidadeFormNew from '@/components/especialidades/EspecialidadeFormCardNew.vue'
import EspecialidadeFormEdit from '@/components/especialidades/EspecialidadeFormCardEdit.vue'
import EspecialidadeDelete from '@/components/especialidades/EspecialidadeFormCardDelete.vue'

const routes = [
  {
    path: '/especialidades',
    component: ListaView,
    props: {
      tituloSecao: 'Especialidades',
      rotuloNovo: 'Nova Especialidade',
      routeNovo: 'especialidadesInserir',
    },
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'especialidadesLista',
        components: {
          lista: EspecialidadeDataTable,
          formulario: FormPlaceholderView,
        },
      },
      {
        path: 'inserir',
        name: 'especialidadesInserir',
        components: {
          lista: EspecialidadeDataTable,
          formulario: EspecialidadeFormNew,
        },
        meta: { requerModal: true },
      },
      {
        path: ':id/alterar',
        name: 'especialidadesAlterar',
        components: {
          lista: EspecialidadeDataTable,
          formulario: EspecialidadeFormEdit,
        },
        props: {
          formulario: true,
        },
        meta: { requerModal: true },
      },
      {
        path: ':id/excluir',
        name: 'especialidadesExcluir',
        components: {
          lista: EspecialidadeDataTable,
          formulario: EspecialidadeDelete,
        },
        props: {
          formulario: true,
        },
        meta: { requerModal: true },
      },
    ],
  },
]

export default routes
