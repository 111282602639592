<template>
  <v-overlay
    :opacity="0.8"
    v-if="isLoading"
    z-index="10"
    data-cy="fullpage-overlay"
  >
    <div class="d-flex flex-column align-center">
      <v-progress-circular
        :size="80"
        :width="6"
        indeterminate
      ></v-progress-circular>
      <span class="text-h5 my-4" v-if="rotulo">{{ rotulo }}</span>
    </div>
  </v-overlay>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth-store'
import { API_STATUS } from '@/utils/enums'

export default {
  name: 'FullpageAuthenticationOverlay',

  computed: {
    ...mapState(useAuthStore, [
      'deveRestaurarSessao',
      'readingStatus',
      'closingStatus',
    ]),

    isLoading() {
      return (
        this.readingStatus === API_STATUS.LOADING ||
        this.closingStatus === API_STATUS.LOADING
      )
    },

    rotulo() {
      if (this.readingStatus === API_STATUS.LOADING)
        return 'carregando dados do usuário...'
      if (this.closingStatus === API_STATUS.LOADING) return 'encerrando...'
      return ''
    },
  },

  async created() {
    // Verifica se existe o token emitido pelo sanctum.
    // Se houver, verifica se o usuário já está autenticado.
    if (this.deveRestaurarSessao) {
      await this.fetchAutenticado()
    }
  },

  methods: {
    ...mapActions(useAuthStore, ['fetchAutenticado']),
  },
}
</script>
